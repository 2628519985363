import config from '@configFile';
import { DealPostType } from '@types';

import { addAffiliateTagToLink } from './formatUtils';

export const getAffiliateTagFromLink = (link: string): string | null => {
  const tagPatterns = [/\?tag=([^&]+)/, /&tag=([^&]+)/, /\/tag=([^&]+)/];

  for (let i = 0; i < tagPatterns.length; i++) {
    const match = (link || '').match(tagPatterns[i]);
    if (match) {
      return match[1];
    }
  }

  return null;
};

export const getPlainAffiliateLink = (
  ASIN: string,
  tag: string = config.AFFILIATE_TAGS.JDB_DEFAULT
) => {
  return `https://www.amazon.com/gp/product/${ASIN}/?tag=${tag}`;
};

export const getProductLink = (
  ASIN: string,
  tag: string = config.AFFILIATE_TAGS.JDB_DEFAULT,
  sLink: string = ''
) => {
  if (!sLink) {
    return `https://www.amazon.com/gp/product/${ASIN}/?fpl=fresh&psc=1&smid=ATVPDKIKX0DER&tag=${tag}`;
  }

  // If there is a link, we need to add the tag to the link
  return addAffiliateTagToLink(sLink, tag);
};

export const getNonAffiliateLink = (ASIN: string) => {
  return `https://www.amazon.com/gp/product/${ASIN}/`;
};

const buildCouponLink = (
  promoCode: string,
  tag: string,
  ASIN: string | null = null
): string => {
  const ASINPart = ASIN ? `&redirectAsin=${ASIN}` : '';
  return `https://www.amazon.com/promotion/psp/${promoCode}?ref=clp_external${ASINPart}&redirectMerchantId=ATVPDKIKX0DER&tag=${tag}`;
};

export const getCouponLink = (
  deal: DealPostType,
  tag: string = config.AFFILIATE_TAGS.JDB_DEFAULT
): string | null => {
  if (!deal?.promoCode) {
    return null;
  }
  return buildCouponLink(deal.promoCode, tag, deal.ASIN);
};

export const getCouponLinkFromCode = (
  promoCode: string,
  tag: string = config.AFFILIATE_TAGS.JDB_DEFAULT
): string => {
  return buildCouponLink(promoCode, tag);
};

function replacePeriodWithExclamation(input: string): string {
  // Check if the string ends with a period
  if (input.endsWith('.')) {
    // Replace the period with an exclamation mark
    return `${input.slice(0, -1)}!`;
  }
  // If no period at the end, return the string unchanged
  return input;
}

export const parseInternalLinkText = (
  input: string
): { before: string; linkText: string; after: string } => {
  const regex = /\*\*(.*?)\*\*/;
  const match = (input || '').match(regex);

  if (match && !input.includes('[link]')) {
    const [fullMatch, linkText] = match;
    const before = replacePeriodWithExclamation(input.split(fullMatch)[0]);
    const after = replacePeriodWithExclamation(input.split(fullMatch)[1]);

    return { before, linkText, after };
  }

  return { before: '', linkText: '', after: '' };
};

export const isInternalLinkTextValid = (input: string): boolean => {
  const { before, after, linkText } = parseInternalLinkText(input);

  if (!linkText || (!before && !after)) {
    return false;
  }

  return true;
};
