import { createTheme } from '@mui/material';
import colors from '@utils/colors';

const themeBase = {
  typography: {
    h1: {
      fontSize: '2rem !important'
    },
    h2: {
      fontSize: '1.75rem !important'
    },
    body2: {
      '@media (max-width: 540px)': {
        fontSize: '14px'
      },
      // fontSize: '1rem',
      lineHeight: '22px'
    },
    body1: {
      fontSize: '14px'
    }
  },
  // style link to be 14px fontSize
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          // is screen width is less than 540px, set width to 100%
          '@media (max-width: 540px)': {
            width: '100%',
            margin: '8px'
          }
          // borderRadius: '10px'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '@media (max-width: 540px)': {
            padding: '10px'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      main: colors.jdbPurple
    },
    background: {
      paper: '#fff'
    },
    error: {
      main: colors.red
    },
    mutedBorder: '#ccc', // Add the muted border color here
    secondaryBackground: '#e7e7e7', // #2f2f2f
    thirdBackground: '#f5f5f5',
    linkColor: '#9a5691', // '#392e50',
    linkHoverColor: '#b397ee',
    secondaryTextColor: '#333',
    newColor: colors.red,
    greenTextColor: '#006600',
    actionsBackgroundColor: '#ffffffad',
    selectedButtonBackgroundColor: '#392e50',
    secondaryLinkColor: '#9a5691',
    favoriteColor: colors.favorite,
    favoriteRibbonColor: colors.favorite,
    couponColor: colors.coupon,
    subscribeSaveColor: colors.subscribeSave,
    popularRibbonColor: colors.trending,
    popularColor: colors.trending
  }
};

export const darkTheme = createTheme({
  ...themeBase,
  components: {
    ...themeBase.components,
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#BDA4F4'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            // Using a lighter version of the primary color
            color: '#b397ee' // Adjust the alpha value for lightness
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#392e50', // Set default background color
          color: '#ffffff', // Set the default text color to white for contrast,

          '&:hover': {
            backgroundColor: '#a07bdc' // A slightly darker shade for hover
          },
          '&.MuiButton-outlinedPrimary': {
            borderColor: '#b397ee', // Set the default border color for secondary outlined buttons
            // color: '#ff4081', // Ensure the text color matches the border
            '&:hover': {
              borderColor: '#b397ee' // Lighter shade for hover effect
              // backgroundColor: 'rgba(255, 64, 129, 0.1)', // Optional: add a light background on hover
            }
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          // Customize the tab indicator color
          '& .MuiTabs-indicator': {
            backgroundColor: '#b397ee' // Change the indicator color here
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // Customize the color of each tab
          color: '#888888', // Default tab color
          '&.Mui-selected': {
            color: '#b397ee' // Color of the selected tab
          },
          '&:hover': {
            color: '#a07bdc' // Color when hovering over a tab
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // This sets the color when the switch is selected (checked)
          color: '#b397ee', // The thumb color when checked (before hovering)
          '&.Mui-checked': {
            color: '#b397ee' // The thumb color when the switch is checked
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#b397ee' // The track color when the switch is checked
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: '#b397ee' // Change label color when focused
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#b397ee' // Change the underline color when focused
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#b397ee' // Change the outline color when focused
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#b397ee' // Change the border color when focused
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#b397ee' // Change label color when focused
          }
        }
      }
    },
    // override the default color of the slider
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#b397ee', // Default color of the slider
          '& .MuiSlider-thumb': {
            backgroundColor: '#b397ee', // Color of the thumb
            '&:hover': {
              backgroundColor: '#a07bdc' // Color of the thumb on hover
            }
          },
          '& .MuiSlider-track': {
            backgroundColor: '#b397ee' // Color of the track
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#b397ee' // Change label color for TextField, Select when focused
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#b397ee', // Default unchecked color
          '&.Mui-checked': {
            color: '#b397ee' // Checked color
          },
          '&.MuiCheckbox-indeterminate': {
            color: '#b397ee' // Indeterminate color
          }
        }
      }
    }
  },
  palette: {
    mode: 'dark',
    text: {
      primary: '#ecececed'
    },
    primary: {
      main: colors.jdbPurple
      // text color main
      // contrastText: 'pink',
      // link color
    },

    background: {
      paper: '#212121'
    },
    error: {
      main: colors.red
    },
    mutedBorder: '#ccc', // Add the muted border color here
    secondaryBackground: '#2f2f2f',
    thirdBackground: '#2f2f2f',
    linkColor: '#bda4f4',
    selectedButtonBackgroundColor: '#a07bdc',
    // bda4f4
    // old color: b397ee
    linkHoverColor: '#fff',
    secondaryTextColor: '#b7b7b7',
    newColor: colors.lightRed,
    greenTextColor: '#59c06d',
    actionsBackgroundColor: '#0000004d',
    secondaryLinkColor: '#9a5691',
    favoriteColor: colors.favoriteDark,
    favoriteRibbonColor: colors.favoriteRibbonDark,
    couponColor: colors.couponDark,
    subscribeSaveColor: colors.subscribeSaveDark,
    popularRibbonColor: colors.trendingRibbonDark,
    popularColor: colors.trendingDark
  }
});

const theme = createTheme(themeBase);

export default theme;
